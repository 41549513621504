@import 'variables';
@import 'bootstrap/bootstrap';
@import 'basicLightbox';

html,
body {
  height: 100%;
}

.full-page main {
  height: 100%;
  margin-top: 80px;
}

.bg-soft-success {
  background-color: rgba(var(--bs-success-rgb), 0.1);
}

.btn-xl {
  padding: 1.25rem 2.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 10rem;
}

.btn-outline-light {
  border: 1px solid var(--bs-white);

  &:hover {
    color: var(--bs-primary);
  }
}

.bi-xl {
  font-size: 1.5rem;
}

label {
  color: rgba(0, 0, 0, 0.4);
}

.page-section {
  padding: 8rem 0;
}

.iti {
  width: 100%;
}

.phone-input {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
  padding: 1rem 0.75rem;
}

hr.divider {
  background-color: var(--bs-primary);
  color: var(--bs-primary);
  height: 0.2rem;
  margin: 1.5rem auto;
  max-width: 5rem;
  opacity: 1;

  &.divider-light {
    background-color: var(--bs-white);
    color: var(--bs-white);
  }
}

.main {
  margin-top: 56px;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.navbar-brand {
  .logo {
    height: 50px;
    width: auto;
  }
}

.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
  background: linear-gradient(
      to bottom,
      rgba(0, 58, 108, 0.9) 0%,
      rgba(0, 58, 108, 0.9) 100%
    ),
    url('/assets/images/event-bg.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;

  .logo {
    height: auto;
    width: 150px;
    transition: all 150ms ease-in-out;

    @include media-breakpoint-up(lg) {
      width: 250px;
    }
  }

  .h1 {
    font-size: 2.25rem;
  }

  .h5 {
    font-size: 1.25rem;
  }

  .headline {
    width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 600px;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 100vh;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;

    p {
      font-size: 1.15rem;
    }

    h1,
    .h1 {
      font-size: 3rem;
    }
  }

  @include media-breakpoint-up(xl) {
    h1,
    .h1 {
      font-size: 3.5rem;
    }
  }
}

// Portfolio
#portfolio .container-fluid,
#portfolio .container-sm,
#portfolio .container-md,
#portfolio .container-lg,
#portfolio .container-xl,
#portfolio .container-xxl {
  max-width: 1920px;

  .portfolio-box {
    position: relative;
    display: block;
    cursor: pointer;

    &:hover .portfolio-box-caption {
      opacity: 1;
    }
  }

  .project-name {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .project-category {
    font-family: 'Merriweather Sans', -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 0.85rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .portfolio-box-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    opacity: 0;
    color: var(--bg-dark);
    background: rgba(var(--bs-secondary-rgb), 0.9);
    transition: opacity 0.25s ease;
    text-align: center;
  }
}

// Footer
.footer-link {
  color: rgba(255, 255, 255, 0.75);

  &:hover {
    color: var(--bs-white);
  }
}

.footer-logo {
  height: 50px;
  width: auto;
}

.basicLightbox {
  z-index: 2000 !important;
}

body:has(.basicLightbox) {
  overflow-y: hidden;
}
